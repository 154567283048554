import React from 'react';

import { HealthcareProviderType } from '@dreryk/egabinet-healthcare-provider-frontend-api';

import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import { assertUnreachable } from '@dreryk/react-common';

import { getHealthcarePracticeP1Path } from '~legacy.p1';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { AccountConfigurationItemProps } from './AccountConfigurationItem.types';
import { AccountConfigurationItemContent } from './AccountConfigurationItemContent';

type P1CertificatesAccountConfigurationItemContent = AccountConfigurationItemProps & {
   healthcareProviderType: HealthcareProviderType;
};

const P1CertificatesAccountConfigurationItemContent = (
   props: P1CertificatesAccountConfigurationItemContent
): JSX.Element => {
   switch (props.healthcareProviderType) {
      case 'HEALTHCARE_PRACTICE':
      case 'HEALTHCARE_ORGANIZATION':
         return (
            <AccountConfigurationItemContent
               label={accountConfigurationInfoTranslationKeys.p1Certificates}
               testId='p1Certificates'
               configured={props.configured}
               url={getHealthcarePracticeP1Path(props.healthcareProviderType)}
               onRedirect={props.onRedirect}
            />
         );
      default:
         return assertUnreachable(props.healthcareProviderType);
   }
};

type P1CertificatesAccountConfigurationItemProps = AccountConfigurationItemProps & {
   healthcareProviderType: HealthcareProviderType;
};

export const P1CertificatesAccountConfigurationItem = (
   props: P1CertificatesAccountConfigurationItemProps
): JSX.Element | null => {
   const protectResource = useResourceProtector();

   return protectResource(
      FeatureId.EGABINETHEALTHCAREPROVIDER_UPDATING_PROVIDER_DATA,
      <P1CertificatesAccountConfigurationItemContent {...props} />,
      null
   );
};
