import React from 'react';

import { HealthcareProviderType } from '@dreryk/egabinet-healthcare-provider-frontend-api';

import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import { assertUnreachable } from '@dreryk/react-common';

import { getHealthcareProviderDataPath } from '~legacy.p1';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { AccountConfigurationItemProps } from './AccountConfigurationItem.types';
import { AccountConfigurationItemContent } from './AccountConfigurationItemContent';

type HealthcareProviderAccountConfigurationItemContentProps = AccountConfigurationItemProps & {
   healthcareProviderType: HealthcareProviderType;
};

const HealthcareProviderAccountConfigurationItemContent = (
   props: HealthcareProviderAccountConfigurationItemContentProps
): JSX.Element => {
   switch (props.healthcareProviderType) {
      case 'HEALTHCARE_PRACTICE':
         return (
            <AccountConfigurationItemContent
               label={accountConfigurationInfoTranslationKeys.healthcarePracticeData}
               testId='healthcarePracticeData'
               configured={props.configured}
               url={getHealthcareProviderDataPath('HEALTHCARE_PRACTICE')}
               onRedirect={props.onRedirect}
            />
         );
      case 'HEALTHCARE_ORGANIZATION':
         return (
            <AccountConfigurationItemContent
               label={accountConfigurationInfoTranslationKeys.healthcareOrganizationData}
               testId='healthcareOrganizationData'
               configured={props.configured}
               url={getHealthcareProviderDataPath('HEALTHCARE_ORGANIZATION')}
               onRedirect={props.onRedirect}
            />
         );
      default:
         return assertUnreachable(props.healthcareProviderType);
   }
};

type HealthcareProviderAccountConfigurationItemProps = AccountConfigurationItemProps & {
   healthcareProviderType: HealthcareProviderType;
};

export const HealthcareProviderAccountConfigurationItem = (
   props: HealthcareProviderAccountConfigurationItemProps
): JSX.Element | null => {
   const protectResource = useResourceProtector();

   return protectResource(
      FeatureId.EGABINETHEALTHCAREPROVIDER_UPDATING_PROVIDER_DATA,
      <HealthcareProviderAccountConfigurationItemContent {...props} />,
      null
   );
};
