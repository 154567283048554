import React from 'react';

import { getCertificatePath } from '~legacy.personnel';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { PractitionerAccountConfigurationItemProps } from './AccountConfigurationItem.types';
import { AccountConfigurationItemContent } from './AccountConfigurationItemContent';

export const PersonalCertificateAccountConfigurationItem = (
   props: PractitionerAccountConfigurationItemProps
): JSX.Element | null => {
   if (props.configured === 'NOT_APPLICABLE') {
      return null;
   }

   return (
      <AccountConfigurationItemContent
         label={accountConfigurationInfoTranslationKeys.personalCertificate}
         testId='personalCertificate'
         configured={props.configured === 'CONFIGURED'}
         url={getCertificatePath()}
         onRedirect={props.onRedirect}
      />
   );
};
