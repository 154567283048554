import React from 'react';

import { useDemoVariantResource } from '@dreryk/demo-variant-frontend';
import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import {
   CalendarIcon,
   CloudIcon,
   MainMenu,
   StatsBarIcon,
   StethoscopeIcon,
   SupportIcon,
   TwoUsersIcon,
} from '@dreryk/react-components';

import { getPatientEntryPath } from '~legacy.patient';
import { getStatisticsPath } from '~legacy.statistics';
import { getAppointmentEntryPath, getVisitModulePaths } from '~legacy.visit';

import { appTranslationKeys } from './translations';

export const CALENDAR_PATH = '/kalendarz';
export const PHARMINDEX_INTEGRATIONS_PATH = '/pharmindexIntegrations';
const HELP_CENTER_LINK = 'https://centrumpomocy.egabinet.dreryk.pl/';

export const AppMainMenu = (): JSX.Element => {
   const protectResource = useResourceProtector();

   return (
      <MainMenu
         testId={'mainMenu'}
         id={'mainMenu'}
         navigation={
            <MainMenu.Navigation>
               {protectResource(
                  FeatureId.EGABINETVISIT_CREATING_APPOINTMENTS,
                  <MainMenu.NavigationItem
                     testId={'visits'}
                     path={getAppointmentEntryPath()}
                     additionalSelectionPaths={getVisitModulePaths()}
                     icon={<StethoscopeIcon />}
                     label={appTranslationKeys.mainMenu.visits}
                  />
               )}
               {protectResource(
                  FeatureId.EGABINETVISIT_CREATING_APPOINTMENTS,
                  <MainMenu.NavigationItem
                     testId={'calendar'}
                     path={CALENDAR_PATH}
                     icon={<CalendarIcon />}
                     label={appTranslationKeys.mainMenu.calendar}
                  />
               )}
               {protectResource(
                  FeatureId.EGABINETPATIENT_CREATING_PATIENTS,
                  <MainMenu.NavigationItem
                     testId={'patients'}
                     path={getPatientEntryPath()}
                     icon={<TwoUsersIcon />}
                     label={appTranslationKeys.mainMenu.patients}
                  />
               )}
               {protectResource(
                  FeatureId.EGABINETSTATISTICS_CREATE,
                  <MainMenu.NavigationItem
                     testId={'statistics'}
                     path={getStatisticsPath()}
                     icon={<StatsBarIcon />}
                     label={appTranslationKeys.mainMenu.statistics}
                  />
               )}
               {protectResource(
                  FeatureId.PHARMINDEX_INTEGRATION,
                  <MainMenu.NavigationItem
                     testId={'pharmindex'}
                     path={PHARMINDEX_INTEGRATIONS_PATH}
                     icon={<CloudIcon />}
                     label={appTranslationKeys.mainMenu.statistics}
                  />
               )}
            </MainMenu.Navigation>
         }
         footer={useDemoVariantResource(
            <MainMenu.Footer>
               <MainMenu.LinkItem
                  testId={'helpCenter'}
                  link={HELP_CENTER_LINK}
                  icon={<SupportIcon />}
                  label={appTranslationKeys.mainMenu.helpCenter}
               />
            </MainMenu.Footer>,
            undefined
         )}
      />
   );
};
