import React from 'react';

import { SessionRemainingTimer } from '@dreryk/egabinet-security-frontend';
import { UserBarGroup } from '@dreryk/react-components';

export const SessionRemainingTimerUserBarGroup = (): JSX.Element | null => {
   return (
      <UserBarGroup testId={'sessionRemainingTime'}>
         <SessionRemainingTimer />
      </UserBarGroup>
   );
};
