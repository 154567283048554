import {
   SubscriptionEnding,
   SubscriptionEndingQuery,
   SubscriptionReminderMessageDisplayedConfirmCommand,
} from '@dreryk/egabinet-healthcare-provider-frontend-api';

import {
   useCqrsCommand,
   UseCqrsCommandResult,
   useCqrsFind,
   UseCqrsQueryResult,
} from '@dreryk/command-query-react-common';

export const useSubscriptionEndingQuery = (): UseCqrsQueryResult<SubscriptionEnding | null> => {
   return useCqrsFind<SubscriptionEnding>(() => new SubscriptionEndingQuery());
};

export const useSubscriptionReminderMessageDisplayedConfirmCommand = (onSuccess: () => void): UseCqrsCommandResult => {
   return useCqrsCommand(() => new SubscriptionReminderMessageDisplayedConfirmCommand(), {
      onSuccess: onSuccess,
   });
};
