import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { History } from 'history';
import { compact } from 'lodash';

import {
   AnalyticsPlugin,
   createGoogleTagManagerPlugin,
   createSimpleLoggerPlugin,
   registerAnalytics,
   trackPage,
   trackTestElementClicks,
} from '@dreryk/analytics-frontend';
import { isDemo } from '@dreryk/demo-variant-frontend';
import { createLogger } from '@dreryk/typescript-logger';

const NO_PLUGINS: never[] = [];

const logger = createLogger('ANALYTICS');

const getGoogleTagManagerPlugin = (): AnalyticsPlugin | undefined => {
   if (!isDemo()) {
      return undefined;
   }

   const containerId = window.APP_ENV.googleTagManagerId;

   if (!containerId) {
      logger.error(() => 'Google Tag Manager containerId not found.');
      return undefined;
   }

   return createGoogleTagManagerPlugin({ containerId: containerId });
};

const getDevelopmentPlugins = (): AnalyticsPlugin[] => {
   const logFn = (...args: unknown[]): void => {
      logger.debug(() => args);
   };
   return process.env.NODE_ENV === 'development' ? compact([createSimpleLoggerPlugin(logFn)]) : NO_PLUGINS;
};

const getProductionPlugins = (): AnalyticsPlugin[] => {
   return process.env.NODE_ENV === 'production' ? compact([getGoogleTagManagerPlugin()]) : NO_PLUGINS;
};

const trackPageChanges = (history: History): (() => void) => {
   trackPage();
   return history.listen(trackPage);
};

export const Analytics = (): null => {
   const history = useHistory();

   useEffect(() => {
      const unregisterAnalytics = registerAnalytics({
         appName: '@dreryk/eGabinet',
         plugins: [...getDevelopmentPlugins(), ...getProductionPlugins()],
      });

      const stopTrackPageChanges = trackPageChanges(history);

      let stopTrackTestElementClicks: null | ReturnType<typeof trackTestElementClicks> = null;
      if (isDemo()) {
         stopTrackTestElementClicks = trackTestElementClicks();
      }

      return () => {
         unregisterAnalytics();
         stopTrackPageChanges();
         stopTrackTestElementClicks?.();
      };
   }, [history]);

   return null;
};
