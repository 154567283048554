import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { useList } from 'react-use';

import { DemoContactWidget, DemoNoticeNotUsingRealData } from '@dreryk/demo-variant-frontend';
import {
   FeatureId,
   ROOT_PATH,
   SecuritySwitch,
   SessionRemainingTimeBanner,
   useResourceProtector,
} from '@dreryk/egabinet-security-frontend';
import { LoaderSpinner } from '@dreryk/react-components';

import { healthcareProviderTypeStorage, useCurrentHealthcareProviderTypeQuery } from '~legacy.healthcare-provider';
import { PharmindexIntegrationPanel } from '~legacy.medicine-database-pharmindex';
import { getHealthcareProviderPath, HealthcareProviderPanel } from '~legacy.p1';
import { usePatientModuleRouter } from '~legacy.patient';
import { getMyAccountPath, MyAccountPanel, SchedulePanel, SchedulePanelLoader } from '~legacy.personnel';
import { PRIVATE_MEDICAL_SERVICE_PATH, PrivateMedicalServicePanel } from '~legacy.private-medical-service';
import { getStatisticsPath, StatisticsRouter } from '~legacy.statistics';
import {
   EwusDaysUntilPasswordExpirationSnackbar,
   ShowVisitButton,
   useAppointmentEntryRedirect,
   useAppointmentOutOfScheduleButton,
   useVisitModuleRouter,
   VisitsHistoryButton,
} from '~legacy.visit';

import { PHARMINDEX_INTEGRATIONS_PATH } from './AppMainMenu';
import { ChangelogModal } from './components/ChangelogModal';
import { Nps } from './components/Nps';
import {
   AccountConfigurationInfoModal,
   AccountConfigurationVerificationEventInterceptor,
} from './features/accountConfigurationInfo';
import { useAccountConfigurationInfoModalTools } from './features/accountConfigurationInfo/accountConfigurationInfo.hooks';
import { usePractitionerAccountConfigurationInfoModalTools } from './features/accountConfigurationInfo/practitioner/practitionerAccountConfigurationInfo.hooks';
import { SubscriptionEndingModal } from './features/subscriptionEnding';
import { SettingsPanel } from './settings/SettingsPanel';
import { SCHEDULE_PATH, SETTINGS_PATH } from './toolbar/UserDropdown';

enum AppModal {
   CHANGELOG = 'CHANGELOG',
   ACCOUNT_CONFIGURATION = 'ACCOUNT_CONFIGURATION',
}

type UseModalsStackReturn = {
   handleOpenFactory: (modalName: AppModal) => () => void;
   handleCloseFactory: (modalName: AppModal) => () => void;
   canOpen: (modalName: AppModal) => boolean;
};
const useAppModalsStack = (): UseModalsStackReturn => {
   const [modalsStack, modalsStackMethods] = useList<AppModal>();
   const handleOpenFactory = useCallback((modalName: AppModal) => () => modalsStackMethods.push(modalName), [
      modalsStackMethods,
   ]);
   const handleCloseFactory = useCallback(
      (modalName: AppModal) => () => modalsStackMethods.filter((item) => modalName !== item),
      [modalsStackMethods]
   );
   return useMemo(
      () => ({
         handleOpenFactory: handleOpenFactory,
         handleCloseFactory: handleCloseFactory,
         canOpen: (modalName: AppModal) => modalsStack.length === 0 || modalName === modalsStack[0],
      }),
      [handleCloseFactory, handleOpenFactory, modalsStack]
   );
};

// eslint-disable-next-line max-lines-per-function
const AppRoutes = (): ReactElement => {
   const protectResource = useResourceProtector();
   // TODO https://dreryk.atlassian.net/browse/DREGAB-1747 remove once this ticket is done
   const { handleCloseFactory, handleOpenFactory, canOpen } = useAppModalsStack();
   const { verifyAccountConfiguration } = useAccountConfigurationInfoModalTools();
   const { verifyPractitionerAccountConfiguration } = usePractitionerAccountConfigurationInfoModalTools();
   const {
      data: healthcareProviderType,
      loading: healthcareProviderTypeLoading,
   } = useCurrentHealthcareProviderTypeQuery();

   const appointmentsEntryRedirect = useAppointmentEntryRedirect();
   const visitModuleRouter = useVisitModuleRouter({
      accountConfigurationVerificationEventInterceptor: AccountConfigurationVerificationEventInterceptor,
   });

   const patientModuleRouter = usePatientModuleRouter({
      appointmentOutOfScheduleButton: useAppointmentOutOfScheduleButton(),
      accountConfigurationVerificationEventInterceptor: AccountConfigurationVerificationEventInterceptor,
      visitsHistoryButton: VisitsHistoryButton,
      showVisitButton: ShowVisitButton,
   });

   useEffect(() => {
      if (healthcareProviderType) {
         healthcareProviderTypeStorage.setProviderType(healthcareProviderType);
      }
   }, [healthcareProviderType]);

   if (healthcareProviderTypeLoading) {
      return <LoaderSpinner />;
   }

   return (
      <>
         <SecuritySwitch>
            <Route exact path={ROOT_PATH}>
               <SecuritySwitch>
                  {protectResource(FeatureId.PHARMINDEX_INTEGRATION, <Redirect to={PHARMINDEX_INTEGRATIONS_PATH} />)}
                  {appointmentsEntryRedirect}
               </SecuritySwitch>
            </Route>
            {visitModuleRouter}
            {patientModuleRouter}
            {protectResource(
               FeatureId.EGABINETSTATISTICS_CREATE,
               <Route path={getStatisticsPath()}>
                  <StatisticsRouter />
               </Route>
            )}
            <Route path={getMyAccountPath()}>
               <MyAccountPanel
                  verifyAccountConfiguration={async () => {
                     await Promise.all([verifyAccountConfiguration(), verifyPractitionerAccountConfiguration()]);
                  }}
               />
            </Route>
            {protectResource(
               FeatureId.EGABINETVISIT_CREATING_VISITS,
               <Route path={SETTINGS_PATH}>
                  <SettingsPanel />
               </Route>
            )}

            {protectResource(
               FeatureId.EGABINETPERSONNEL_UPDATING_AVAILABILITY,
               <Route path={SCHEDULE_PATH}>
                  <SchedulePanelLoader>
                     {({ currentDoctor, doctors }) => <SchedulePanel defaultDoctor={currentDoctor} doctors={doctors} />}
                  </SchedulePanelLoader>
               </Route>
            )}
            {healthcareProviderType &&
               protectResource(
                  FeatureId.EGABINETHEALTHCAREPROVIDER_UPDATING_PROVIDER_DATA,
                  <Route path={getHealthcareProviderPath(healthcareProviderType)}>
                     <HealthcareProviderPanel
                        healthcareProviderType={healthcareProviderType}
                        verifyAccountConfiguration={verifyAccountConfiguration}
                     />
                  </Route>
               )}
            {protectResource(
               FeatureId.EGABINETPRIVATEMEDICALSERVICE_MANAGING_SERVICES,
               <Route path={PRIVATE_MEDICAL_SERVICE_PATH}>
                  <PrivateMedicalServicePanel />
               </Route>
            )}
            {protectResource(
               FeatureId.PHARMINDEX_INTEGRATION,
               <Route path={PHARMINDEX_INTEGRATIONS_PATH}>
                  <PharmindexIntegrationPanel />
               </Route>
            )}
         </SecuritySwitch>

         <Nps />
         <ChangelogModal
            canOpen={canOpen(AppModal.CHANGELOG)}
            onOpen={handleOpenFactory(AppModal.CHANGELOG)}
            onClose={handleCloseFactory(AppModal.CHANGELOG)}
         />
         <AccountConfigurationInfoModal
            canOpen={canOpen(AppModal.ACCOUNT_CONFIGURATION)}
            onOpen={handleOpenFactory(AppModal.ACCOUNT_CONFIGURATION)}
            onClose={handleCloseFactory(AppModal.ACCOUNT_CONFIGURATION)}
         />
         {protectResource(FeatureId.EGABINETHEALTHCAREPROVIDER_UPDATING_PROVIDER_DATA, <SubscriptionEndingModal />)}
         <DemoNoticeNotUsingRealData />
         <DemoContactWidget />
         <EwusDaysUntilPasswordExpirationSnackbar />
         <SessionRemainingTimeBanner />
      </>
   );
};

export { AppRoutes };
