import React from 'react';

import { ChangelogModal as BaseChangelogModal } from '@dreryk/react-components';

import { appTranslationKeys } from '../translations';

type ChangelogModalProps = {
   onOpen: VoidFunction;
   onClose: VoidFunction;
   canOpen: boolean;
};

const ChangelogModal = (props: ChangelogModalProps): JSX.Element | null => {
   const { changelogEndpoint, changelogLink } = window.APP_ENV;

   if (!changelogEndpoint || !changelogLink || !props.canOpen) {
      return null;
   }

   return (
      <BaseChangelogModal
         endpoint={changelogEndpoint}
         changelogLink={changelogLink}
         title={appTranslationKeys.whatsNew}
         testId={'changelog'}
         onOpen={props.onOpen}
         onClose={props.onClose}
      />
   );
};

export { ChangelogModal };
