import { compact } from 'lodash';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import {
   FeatureId,
   useLogoutDropdownItem,
   useResourceProtector,
   useSessionState,
} from '@dreryk/egabinet-security-frontend';
import { createTranslatedText } from '@dreryk/react-common';
import { Dropdown, DropdownItem, LinkVariant, PersonIcon } from '@dreryk/react-components';

import { useResourceBranchByHealthcareProviderType } from '~legacy.healthcare-provider';
import { useMyOrganizationDropdownItem, useMyPracticeDropdownItem } from '~legacy.p1';
import { getMyAccountPath } from '~legacy.personnel';
import { usePrivateMedicalServiceViewDropdownItem } from '~legacy.private-medical-service';

import { useFlushAccountVerificationState } from '../features/accountConfigurationInfo/accountConfigurationInfo.hooks';
import { useFlushPractitionerVerificationState } from '../features/accountConfigurationInfo/practitioner/practitionerAccountConfigurationInfo.hooks';
import { userDropdownTranslationKeys } from '../translations';

export const SCHEDULE_PATH = '/harmonogram';
export const SETTINGS_PATH = '/ustawienia';

const useDropdownItems = (): DropdownItem[] => {
   const history = useHistory();
   const protectResource = useResourceProtector();
   const flushAccountVerificationState = useFlushAccountVerificationState();
   const flushPractitionerVerificationState = useFlushPractitionerVerificationState();
   const myOrganizationDropdownItem = useMyOrganizationDropdownItem();
   const myPracticeDropdownItem = useMyPracticeDropdownItem();

   return compact([
      {
         id: 'account',
         label: userDropdownTranslationKeys.myAccount,
         onSelect: () => history.push(getMyAccountPath()),
      },
      protectResource(
         FeatureId.EGABINETHEALTHCAREPROVIDER_UPDATING_PROVIDER_DATA,
         useResourceBranchByHealthcareProviderType(myOrganizationDropdownItem, myPracticeDropdownItem)
      ),
      protectResource(
         FeatureId.EGABINETPRIVATEMEDICALSERVICE_MANAGING_SERVICES,
         usePrivateMedicalServiceViewDropdownItem()
      ),
      protectResource(FeatureId.EGABINETPERSONNEL_UPDATING_AVAILABILITY, {
         id: 'schedule',
         label: userDropdownTranslationKeys.schedule,
         onSelect: () => history.push(SCHEDULE_PATH),
      }),
      protectResource(FeatureId.EGABINETVISIT_CREATING_VISITS, {
         id: 'settings',
         label: userDropdownTranslationKeys.settings,
         onSelect: () => history.push(SETTINGS_PATH),
      }),
      useLogoutDropdownItem({
         onLogout: () => {
            flushAccountVerificationState();
            flushPractitionerVerificationState();
         },
      }),
   ]);
};

const UserDropdown = (): ReactElement => {
   const { account } = useSessionState();
   const items = useDropdownItems();

   return (
      <Dropdown
         name='currentUserDropdown'
         testId={'currentUserDropdown'}
         toggleType={'link'}
         toggleIcon={<PersonIcon />}
         toggleLabel={createTranslatedText(account?.displayName)}
         toggleLinkVariant={LinkVariant.SECONDARY}
         items={items}
         maxHeight={items.length}
      />
   );
};

export { UserDropdown };
