import React from 'react';

import { NavTabs } from '@dreryk/react-components';

import { VisitTemplatesPanel } from '~legacy.visit';

import { settingsTranslationKeys } from '../translations';

enum SettingsNavTabsParams {
   VISIT_TEMPLATES = 'szablony-wizyt',
}

export const SettingsPanel = (): JSX.Element => {
   return (
      <NavTabs<SettingsNavTabsParams> testId={'settings'}>
         <NavTabs.Tab
            testId={SettingsNavTabsParams.VISIT_TEMPLATES}
            param={SettingsNavTabsParams.VISIT_TEMPLATES}
            label={settingsTranslationKeys.tabs.visitTemplates}
         >
            <VisitTemplatesPanel />
         </NavTabs.Tab>
      </NavTabs>
   );
};
