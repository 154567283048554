import React from 'react';

import { TranslatableText, useTranslate } from '@dreryk/react-common';
import {
   Button,
   ButtonVariant,
   Link,
   StyledFlex,
   StyledMargin,
   Text,
   useHistoryPushWithBackAction,
} from '@dreryk/react-components';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { AccountConfigurationInfoStatus } from './AccountConfigurationInfoStatus';
import { StyledAccountConfigurationInfoModalLink } from './StyledAccountConfigurationInfoModalLink';
import { AccountConfigurationItemProps } from './AccountConfigurationItem.types';

type AccountConfigurationItemContentProps = AccountConfigurationItemProps & {
   label: TranslatableText;
   url: string;
   testId: string;
};

export const AccountConfigurationItemContent = (props: AccountConfigurationItemContentProps): JSX.Element => {
   const translate = useTranslate();
   const push = useHistoryPushWithBackAction(false);
   const handleLinkClick: VoidFunction = () => {
      push(props.url, {});
      props.onRedirect?.();
   };
   return (
      <StyledMargin as='li' bottom={12}>
         <StyledFlex gap='24px' alignItems='center' justifyContent='space-between'>
            <StyledAccountConfigurationInfoModalLink>
               {props.configured ? (
                  <Link label={props.label} testId={props.testId} onClick={handleLinkClick} />
               ) : (
                  <Text testId={props.testId}>{translate(props.label)}</Text>
               )}
            </StyledAccountConfigurationInfoModalLink>
            {props.configured ? (
               <AccountConfigurationInfoStatus testId={props.testId} />
            ) : (
               <Button
                  testId={props.testId}
                  label={accountConfigurationInfoTranslationKeys.configure}
                  onClick={handleLinkClick}
                  variant={ButtonVariant.SECONDARY}
                  compact
               />
            )}
         </StyledFlex>
      </StyledMargin>
   );
};
