import React from 'react';
import styled from 'styled-components';

import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import { Translate } from '@dreryk/react-common';
import { UserBarGroup, UserBarGroupLinkWrap } from '@dreryk/react-components';

import { appTranslationKeys } from '../translations';

const StyledUserBarGroupLinkWrap = styled(UserBarGroupLinkWrap)`
   color: ${(props) => props.theme.secondaryDark};
`;
StyledUserBarGroupLinkWrap.displayName = 'StyledUserBarGroupLinkWrap';

export const ExperimentalMonitUserBarGroup = (): JSX.Element | null => {
   const protectResource = useResourceProtector();
   const experimental = protectResource(FeatureId.EXPERIMENTAL, true, false);

   if (experimental)
      return (
         <UserBarGroup testId={'experimentalMonit'}>
            <StyledUserBarGroupLinkWrap testId={'experimentalMonit'}>
               <Translate>{appTranslationKeys.experimental}</Translate>
            </StyledUserBarGroupLinkWrap>
         </UserBarGroup>
      );

   return null;
};
