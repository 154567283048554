import React from 'react';
import { useUpdateEffect } from 'react-use';

import { useFormController } from '@dreryk/react-common';
import { CheckIcon, MoonIcon, SunnyIcon, Toggle, ToggleVariant, useThemeContext } from '@dreryk/react-components';

export const ThemeToggle = (): JSX.Element => {
   const { isDarkMode, toggleTheme } = useThemeContext();
   const { register, watch } = useFormController({
      lightMode: !isDarkMode,
   });

   useUpdateEffect(toggleTheme, [watch('lightMode')]);

   return (
      <Toggle
         controller={register('lightMode')}
         variant={ToggleVariant.SECONDARY}
         testId={'switchTheme'}
         leftSlot={<MoonIcon />}
         icon={<CheckIcon />}
         rightSlot={<SunnyIcon />}
         asSwitch
      />
   );
};
