import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Status } from '@dreryk/react-components';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

enum ConfigurationStatus {
   READY = 'READY',
}

type AccountConfigurationInfoStatusProps = {
   testId: string;
};

export const AccountConfigurationInfoStatus = (props: AccountConfigurationInfoStatusProps): JSX.Element => {
   const theme = useTheme();
   const options = useMemo(
      () => ({
         [ConfigurationStatus.READY]: {
            color: theme.success,
            backgroundColor: theme.successLighter,
         },
      }),
      [theme]
   );

   return (
      <Status
         testId={props.testId}
         type={ConfigurationStatus.READY}
         label={accountConfigurationInfoTranslationKeys.ready}
         options={options}
         displayIndicator
      />
   );
};
