/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// TODO: Move browserslist into react-components import { BrowserNotSupportedSnackbar } from '@libs/browserslist';

import { CqrsClientProvider } from '@dreryk/command-query-react-common';
import {
   GlobalErrorBoundary,
   SecurityDependenciesContextProvider,
   SecurityRoutes,
   SystemId,
} from '@dreryk/egabinet-security-frontend';
import {
   CookiesConsentSnackbar,
   DarkTheme,
   MainLayout,
   SnackbarEventListener,
   StandardTheme,
   ThemeProvider,
} from '@dreryk/react-components';

import { AppMainMenu } from './AppMainMenu';
import { AppRoutes } from './AppRoutes';
import { Analytics } from './components/Analytics';
import { SalesMode } from './components/SalesMode';
import { LocationLogger } from './development/LocationLogger';
import { MainToolbar } from './toolbar/MainToolbar';

export const App = (): JSX.Element => {
   return (
      <ThemeProvider light={StandardTheme} dark={DarkTheme}>
         <GlobalErrorBoundary>
            <CqrsClientProvider>
               <BrowserRouter>
                  <SnackbarEventListener />
                  <LocationLogger />
                  <SecurityDependenciesContextProvider systemId={SystemId.E_GABINET}>
                     <SecurityRoutes
                        authorizedRoutes={
                           <MainLayout menu={<AppMainMenu />} toolbar={<MainToolbar />} testId={'mainLayout'}>
                              <AppRoutes />
                           </MainLayout>
                        }
                     >
                        <Analytics />
                        <CookiesConsentSnackbar />
                        {/* TODO: Move Browserlist into react-components <BrowserNotSupportedSnackbar />  */}
                        <SalesMode />
                     </SecurityRoutes>
                  </SecurityDependenciesContextProvider>
               </BrowserRouter>
            </CqrsClientProvider>
         </GlobalErrorBoundary>
      </ThemeProvider>
   );
};
