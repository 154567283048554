import React, { ReactNode, useCallback } from 'react';

import { ModalWidth, ShowModalEventInterceptor } from '@dreryk/react-components';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { isAccountConfigured, useAccountConfigurationInfoModalTools } from './accountConfigurationInfo.hooks';
import { AccountConfigurationInfoModalContent } from './AccountConfigurationInfoModalContent';
import { AccountConfigurationInfoModalContentLoader } from './AccountConfigurationInfoModalContent.loader';
import {
   isPractitionerAccountConfigured,
   usePractitionerAccountConfigurationInfoModalTools,
} from './practitioner/practitionerAccountConfigurationInfo.hooks';
import { useDispatchNotConfiguredAccountSnackbar } from './shared/useDispatchNotConfiguredAccountSnackbar.hooks';

type AccountConfigurationVerificationEventInterceptorProps = {
   children: ReactNode;
};

export const AccountConfigurationVerificationEventInterceptor = (
   props: AccountConfigurationVerificationEventInterceptorProps
): JSX.Element => {
   const dispatchNotConfiguredAccountSnackbar = useDispatchNotConfiguredAccountSnackbar();
   const { fetchConfigurationData, configurationData, title } = useAccountConfigurationInfoModalTools();
   const {
      fetchPractitionerConfiguration,
      practitionerConfiguration,
   } = usePractitionerAccountConfigurationInfoModalTools();

   const shouldShowModal = useCallback(async () => {
      try {
         const configurationsValid = await Promise.all([
            !isAccountConfigured(await fetchConfigurationData()),
            !isPractitionerAccountConfigured(await fetchPractitionerConfiguration()),
         ]);

         return configurationsValid.some(Boolean);
      } catch {
         return false;
      }
   }, [fetchConfigurationData, fetchPractitionerConfiguration]);

   return (
      <ShowModalEventInterceptor
         testId='accountConfigurationVerification'
         shouldShowModal={shouldShowModal}
         title={title}
         content={
            configurationData && practitionerConfiguration ? (
               <AccountConfigurationInfoModalContentLoader>
                  {({ healthcareProviderType }) => (
                     <AccountConfigurationInfoModalContent
                        healthcareProviderConfiguration={configurationData}
                        practitionerConfiguration={practitionerConfiguration}
                        healthcareProviderType={healthcareProviderType}
                     />
                  )}
               </AccountConfigurationInfoModalContentLoader>
            ) : (
               <></>
            )
         }
         modalWidth={ModalWidth.NORMAL}
         confirmationButtonLabel={accountConfigurationInfoTranslationKeys.close}
         onConfirm={(resolve, reject, closeModal) => {
            reject();
            closeModal();
            dispatchNotConfiguredAccountSnackbar(configurationData, practitionerConfiguration);
         }}
         onCancel={() => dispatchNotConfiguredAccountSnackbar(configurationData, practitionerConfiguration)}
         cancelButtonDisabled
      >
         {props.children}
      </ShowModalEventInterceptor>
   );
};
