import React from 'react';

import { getPersonalDataPath } from '~legacy.personnel';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { PractitionerAccountConfigurationItemProps } from './AccountConfigurationItem.types';
import { AccountConfigurationItemContent } from './AccountConfigurationItemContent';

export const PwzAndSpecializationAccountConfigurationItem = (
   props: PractitionerAccountConfigurationItemProps
): JSX.Element | null => {
   if (props.configured === 'NOT_APPLICABLE') {
      return null;
   }

   return (
      <AccountConfigurationItemContent
         label={accountConfigurationInfoTranslationKeys.pwzAndSpecialization}
         testId='pwzAndSpecialization'
         configured={props.configured === 'CONFIGURED'}
         url={getPersonalDataPath()}
         onRedirect={props.onRedirect}
      />
   );
};
