import { isEmpty, noop } from 'lodash';
import { useEffectOnce } from 'react-use';

export const Nps = (): null => {
   const { npsScriptLink } = window.APP_ENV;

   useEffectOnce(() => {
      if (isEmpty(npsScriptLink)) {
         return noop;
      }

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.innerHTML = `
<!-- Start of Survicate (www.survicate.com) code -->
(function(w) {
    var s = document.createElement("script");
    s.src = "${npsScriptLink}";
    s.async = true;
    var e = document.getElementsByTagName("script")[0];
    e.parentNode.insertBefore(s, e);
})(window);
<!-- End of Survicate code -->
      `;

      document.head.appendChild(scriptElement);

      return () => {
         document.head.removeChild(scriptElement);
      };
   });

   return null;
};
