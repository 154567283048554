import { merge } from 'lodash';

import { translations as demoVariantTranslations } from '@dreryk/demo-variant-frontend';
import { translations as drerykwebMainTranslations } from '@dreryk/drerykweb-main-frontend';
import { translations as drerykwebSecurityTranslations } from '@dreryk/egabinet-security-frontend';
import { initializeI18n, translations as reactCommonComponentsTranslations } from '@dreryk/react-common';
import { translations as drerykwebReactComponentsTranslations } from '@dreryk/react-components';

import { translations as egabinetDocplannetTranslations } from '~legacy.docplanner';
import { translations as egabinetInvoiceTranslationKeys } from '~legacy.invoice';
import { translations as medicineDatabasePharmindexTranslations } from '~legacy.medicine-database-pharmindex';
import { translations as drerykwebP1Translations } from '~legacy.p1';
import { translations as patientTranslations } from '~legacy.patient';
import { translations as egabinetPatientPortalTranslations } from '~legacy.patient-portal';
import { translations as egabinetPersonnelTranslations } from '~legacy.personnel';
import { translations as egabinetPrivateMedicalServiceTranslations } from '~legacy.private-medical-service';
import { translations as egabinetStatisticsTranslations } from '~legacy.statistics';
import { translations as visitTranslations } from '~legacy.visit';

// Moment issue: https://github.com/moment/moment/issues/5926
import 'moment/dist/locale/pl';

import { translations as appTranslations } from './translations';

export const initializeTranslations = async (): Promise<void> => {
   await initializeI18n({
      locale: 'pl',
      timezone: 'Europe/Warsaw',
      resources: merge(
         {},
         reactCommonComponentsTranslations,
         drerykwebMainTranslations,
         ...visitTranslations,
         drerykwebReactComponentsTranslations,
         medicineDatabasePharmindexTranslations,
         drerykwebP1Translations,
         patientTranslations,
         egabinetPersonnelTranslations,
         drerykwebSecurityTranslations,
         demoVariantTranslations,
         egabinetStatisticsTranslations,
         egabinetPrivateMedicalServiceTranslations,
         appTranslations,
         egabinetDocplannetTranslations,
         egabinetPatientPortalTranslations,
         egabinetInvoiceTranslationKeys
      ),
   });
};
