import React, { ReactElement } from 'react';

import { DemoNoticeUsingDemoUserBarGroup } from '@dreryk/demo-variant-frontend';
import { UserBar, UserBarGroup, UserBarGroupLinkWrap } from '@dreryk/react-components';

import { ExperimentalMonitUserBarGroup } from './ExperimentalMonitUserBarGroup';
import { PayInvoicesUserBarGroup } from './PayInvoicesUserBarGroup';
import { SessionRemainingTimerUserBarGroup } from './SessionRemainingTimerUserBarGroup';
import { ThemeToggle } from './ThemeToggle';
import { UserDropdown } from './UserDropdown';

export const MainToolbar = (): ReactElement => {
   return (
      <UserBar>
         <DemoNoticeUsingDemoUserBarGroup />
         <ExperimentalMonitUserBarGroup />
         <PayInvoicesUserBarGroup />
         <SessionRemainingTimerUserBarGroup />
         <UserBarGroup testId={'themeToggle'}>
            <UserBarGroupLinkWrap testId={'themeToggle'}>
               <ThemeToggle />
            </UserBarGroupLinkWrap>
         </UserBarGroup>
         <UserBarGroup testId={'userDropdown'}>
            <UserBarGroupLinkWrap testId={'userDropdown'}>
               <UserDropdown />
            </UserBarGroupLinkWrap>
         </UserBarGroup>
      </UserBar>
   );
};
