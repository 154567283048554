import React from 'react';
import { Trans } from 'react-i18next';

import { SubscriptionEnding } from '@dreryk/egabinet-healthcare-provider-frontend-api';

import { Logo, StrongText, Text } from '@dreryk/react-components';
import { createSingleInterpolatedTranslationKey, dateFormatter, useTranslate } from '@dreryk/react-common';

import { subscriptionEndingTranslationKeys } from '../../translations';

import { StyledSubscriptionEndingModalList } from './StyledSubscriptionEndingModalList';
import { StyledSubscriptionEndingModalLogo } from './StyledSubscriptionEndingModalLogo';

type SubscriptionEndingModalContentProps = {
   subscriptionEnding: SubscriptionEnding;
};

export const SubscriptionEndingModalContent = (props: SubscriptionEndingModalContentProps): JSX.Element => {
   const translate = useTranslate();

   return (
      <>
         <div>
            <Text testId={'dearUser'}>{translate(subscriptionEndingTranslationKeys.dearUser)}</Text>
            <StyledSubscriptionEndingModalList>
               <li>
                  <Text testId={'anotherSubscriptionYearStart'}>
                     {
                        <Trans
                           i18nKey={subscriptionEndingTranslationKeys.anotherSubscriptionYearStart}
                           values={{
                              date: dateFormatter(props.subscriptionEnding.nextSubscriptionStartDate),
                           }}
                           components={{ bold: <StrongText testId={'date'} /> }}
                        />
                     }
                  </Text>
               </li>
               <li>
                  <Text testId={'nextBillingPeriodInvoiceSend'}>
                     {translate(
                        createSingleInterpolatedTranslationKey(
                           subscriptionEndingTranslationKeys.nextBillingPeriodInvoiceSend,
                           'email',
                           props.subscriptionEnding.healthcareProviderEmail
                        )
                     )}
                  </Text>
               </li>
            </StyledSubscriptionEndingModalList>
            <p>
               <Text testId={'nextBillingPeriodInvoiceAutomaticPayment'}>
                  {translate(subscriptionEndingTranslationKeys.nextBillingPeriodInvoiceAutomaticPayment)}
               </Text>
            </p>
         </div>
         <StrongText testId={'thankYou'}>{translate(subscriptionEndingTranslationKeys.thankYou)}</StrongText>
         <StyledSubscriptionEndingModalLogo>
            <Logo testId={'logo'} />
         </StyledSubscriptionEndingModalLogo>
      </>
   );
};
