import React from 'react';
import { Trans } from 'react-i18next';

import { Link, LinkSize, LinkTarget, Notification, NotificationSize, NotificationType } from '@dreryk/react-components';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

export const AccountConfigurationInfoHelpTextNotification = (): JSX.Element => {
   return (
      <Notification
         testId='accountInstructionConfigurationLink'
         type={NotificationType.INFO}
         size={NotificationSize.SMALLER}
         label={
            <Trans
               i18nKey={accountConfigurationInfoTranslationKeys.doYouNeedHelp}
               components={{
                  link: (
                     <Link
                        testId='configurationInstruction'
                        label={accountConfigurationInfoTranslationKeys.instruction}
                        target={LinkTarget.BLANK}
                        block={false}
                        size={LinkSize.SMALL}
                        href={'https://centrumpomocy.egabinet.dreryk.pl/pierwsze-kroki/konfiguracja-konta-i-placowki'}
                     />
                  ),
               }}
            />
         }
      />
   );
};
