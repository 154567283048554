import React, { useState } from 'react';

import { isDemo } from '@dreryk/demo-variant-frontend';
import { Modal, ModalCancelButton, ModalCloseButton, ModalConfirmButton, ModalWidth } from '@dreryk/react-components';

import { subscriptionEndingTranslationKeys } from '../../translations';

import {
   useSubscriptionEndingQuery,
   useSubscriptionReminderMessageDisplayedConfirmCommand,
} from './SubscriptionEndingModal.hooks';
import { SubscriptionEndingModalContent } from './SubscriptionEndingModalContent';

export const SubscriptionEndingModal = (): JSX.Element | null => {
   const [opened, setOpened] = useState(true);
   const close: VoidFunction = () => {
      setOpened(false);
   };
   const { executeAsync: confirmDisplayed } = useSubscriptionReminderMessageDisplayedConfirmCommand(close);

   const openContactPage: VoidFunction = () => {
      window.open('https://dreryk.pl/kontakt/', '_blank');
   };

   const { data: subscriptionEnding } = useSubscriptionEndingQuery();

   const canOpen = (): boolean | undefined => {
      return !isDemo() && opened && subscriptionEnding?.shouldReminderMessageBeDisplayed;
   };

   return canOpen() ? (
      <Modal
         testId='subscriptionEnding'
         width={ModalWidth.NORMAL}
         title={subscriptionEndingTranslationKeys.thankYouForBeingWithUs}
      >
         <ModalCloseButton onClick={confirmDisplayed} />
         <SubscriptionEndingModalContent subscriptionEnding={subscriptionEnding!} />
         <ModalCancelButton label={subscriptionEndingTranslationKeys.contactUs} onClick={openContactPage} />
         <ModalConfirmButton label={subscriptionEndingTranslationKeys.close} onClick={confirmDisplayed} />
      </Modal>
   ) : null;
};
