import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { createLogger } from '@dreryk/typescript-logger';

const logger = createLogger('LOCATION');

export const LocationLogger = (): null => {
   const location = useLocation();
   useEffect(() => {
      logger.debug(() => location);
   }, [location]);

   return null;
};
