import React from 'react';

import { HealthcareProviderType } from '@dreryk/egabinet-healthcare-provider-frontend-api';
import { PractitionerConfigurationVerificationResult } from '@dreryk/egabinet-personnel-frontend-api';
import { ConfigurationVerificationResult } from '@dreryk/egabinet-visit-frontend-api';

import { StyledFlex } from '@dreryk/react-components';

import { AccountConfigurationInfoHelpTextNotification } from './AccountConfigurationInfoHelpTextNotification';
import { HealthcareProviderAccountConfigurationItem } from './HealthcareProviderAccountConfigurationItem';
import { HealthcareProviderAdministratorLackConfigurationStatus } from './HealthcareProviderAdministratorLackConfigurationStatus';
import { P1CertificatesAccountConfigurationItem } from './P1CertificatesAccountConfigurationItem.';
import { PersonalCertificateAccountConfigurationItem } from './PersonalCertificateAccountConfigurationItem';
import { PeselAndProfessionAccountConfigurationItem } from './PeselAndProfessionAccountConfigurationItem';
import { PwzAndSpecializationAccountConfigurationItem } from './PwzAndSpecializationAccountConfigurationItem';
import { StyledAccountConfigurationInfoModalList } from './StyledAccountConfigurationInfoModalList';

type AccountConfigurationInfoModalContentProps = {
   healthcareProviderType: HealthcareProviderType;
   healthcareProviderConfiguration: ConfigurationVerificationResult;
   practitionerConfiguration: PractitionerConfigurationVerificationResult;
   onRedirect?: VoidFunction;
};

export const AccountConfigurationInfoModalContent = (props: AccountConfigurationInfoModalContentProps): JSX.Element => {
   return (
      <StyledFlex direction={'column'} gap='24px'>
         <HealthcareProviderAdministratorLackConfigurationStatus
            healthcareProviderDataConfigured={props.healthcareProviderConfiguration.healthcareProviderDataConfigured}
            p1CertificateConfigured={props.healthcareProviderConfiguration.p1CertificateConfigured}
         />

         <StyledFlex gap='12px'>
            <StyledAccountConfigurationInfoModalList>
               <PersonalCertificateAccountConfigurationItem
                  configured={props.practitionerConfiguration.personalCertificateConfigured}
                  onRedirect={props.onRedirect}
               />
               <P1CertificatesAccountConfigurationItem
                  configured={props.healthcareProviderConfiguration.p1CertificateConfigured}
                  onRedirect={props.onRedirect}
                  healthcareProviderType={props.healthcareProviderType}
               />
               <PwzAndSpecializationAccountConfigurationItem
                  configured={props.practitionerConfiguration.medicalLicenceNumberAndSpecializationConfigured}
                  onRedirect={props.onRedirect}
               />
               <PeselAndProfessionAccountConfigurationItem
                  configured={props.practitionerConfiguration.peselAndParamedicalProfessionConfigured}
                  onRedirect={props.onRedirect}
               />
               <HealthcareProviderAccountConfigurationItem
                  configured={props.healthcareProviderConfiguration.healthcareProviderDataConfigured}
                  onRedirect={props.onRedirect}
                  healthcareProviderType={props.healthcareProviderType}
               />
            </StyledAccountConfigurationInfoModalList>
         </StyledFlex>

         <AccountConfigurationInfoHelpTextNotification />
      </StyledFlex>
   );
};
