import React from 'react';
import styled from 'styled-components';

import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import { Translate } from '@dreryk/react-common';
import { UserBarGroup, UserBarGroupLinkWrap } from '@dreryk/react-components';

import { appTranslationKeys } from '../translations';

const StyledUserBarGroupLinkWrap = styled(UserBarGroupLinkWrap)`
   color: ${(props) => props.theme.secondaryDark};
`;
StyledUserBarGroupLinkWrap.displayName = 'StyledUserBarGroupLinkWrap';

export const PayInvoicesUserBarGroup = (): JSX.Element | null => {
   const protectResource = useResourceProtector();
   const canSearchVisitOperations = protectResource(FeatureId.EGABINETVISIT_SEARCHING_VISIT_OPERATIONS, true, false);
   const canPerformVisit = protectResource(FeatureId.EGABINETVISIT_CREATING_VISITS, true, false);

   if (canSearchVisitOperations && !canPerformVisit)
      return (
         <UserBarGroup testId={'payInvoices'}>
            <StyledUserBarGroupLinkWrap testId={'payInvoices'}>
               <Translate>{appTranslationKeys.payInvoices}</Translate>
            </StyledUserBarGroupLinkWrap>
         </UserBarGroup>
      );

   return null;
};
