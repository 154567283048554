import React from 'react';
import { useMount, useUnmount } from 'react-use';

import { Modal, ModalCloseButton, ModalWidth } from '@dreryk/react-components';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

import { useAccountConfigurationInfoModalTools } from './accountConfigurationInfo.hooks';
import { AccountConfigurationInfoModalContent } from './AccountConfigurationInfoModalContent';
import { AccountConfigurationInfoModalContentLoader } from './AccountConfigurationInfoModalContent.loader';
import { usePractitionerAccountConfigurationInfoModalTools } from './practitioner/practitionerAccountConfigurationInfo.hooks';
import { useDispatchNotConfiguredAccountSnackbar } from './shared/useDispatchNotConfiguredAccountSnackbar.hooks';

type AccountConfigurationInfoModalProps = {
   onOpen?: VoidFunction;
   onClose?: VoidFunction;
   canOpen: boolean;
};

export const AccountConfigurationInfoModal = (props: AccountConfigurationInfoModalProps): JSX.Element | null => {
   const {
      opened: accountConfigurationModalOpened,
      close: accountConfigurationModalClose,
      configurationData,
      verifyAccountConfiguration,
      title,
   } = useAccountConfigurationInfoModalTools({
      onOpen: props.onOpen,
      onClose: props.onClose,
   });

   const {
      practitionerConfigurationModalOpened,
      practitionerConfigurationModalClose,
      practitionerConfiguration,
      verifyPractitionerAccountConfiguration,
   } = usePractitionerAccountConfigurationInfoModalTools({
      onOpen: props.onOpen,
      onClose: props.onClose,
   });

   useMount(async () => {
      await Promise.all([verifyAccountConfiguration(), verifyPractitionerAccountConfiguration()]);
   });

   useUnmount(() => {
      practitionerConfigurationModalClose();
      accountConfigurationModalClose();
   });

   const dispatchNotConfiguredAccountSnackbar = useDispatchNotConfiguredAccountSnackbar();
   const handleClose: VoidFunction = () => {
      dispatchNotConfiguredAccountSnackbar(configurationData, practitionerConfiguration);
      practitionerConfigurationModalClose();
      accountConfigurationModalClose();
   };

   if (
      !props.canOpen ||
      (!accountConfigurationModalOpened && !practitionerConfigurationModalOpened) ||
      !configurationData ||
      !practitionerConfiguration
   ) {
      return null;
   }

   return (
      <Modal
         testId='accountConfigurationInfo'
         width={ModalWidth.NORMAL}
         title={title}
         confirmationButtonLabel={accountConfigurationInfoTranslationKeys.close}
         onConfirm={handleClose}
         onCloseRequest={handleClose}
      >
         <AccountConfigurationInfoModalContentLoader>
            {({ healthcareProviderType }) => (
               <AccountConfigurationInfoModalContent
                  healthcareProviderConfiguration={configurationData}
                  practitionerConfiguration={practitionerConfiguration}
                  healthcareProviderType={healthcareProviderType}
                  onRedirect={() => {
                     accountConfigurationModalClose();
                     practitionerConfigurationModalClose();
                  }}
               />
            )}
         </AccountConfigurationInfoModalContentLoader>
         <ModalCloseButton onClick={handleClose} />
      </Modal>
   );
};
