import { registerCqrsGateway, setCqrsClientOptions, setCqrsLogger } from '@dreryk/command-query-react-common';
import { Gateway } from '@dreryk/command-query-ws-client';
import { asyncRejection } from '@dreryk/egabinet-security-frontend';
import { createLogger } from '@dreryk/typescript-logger';
import { initializeDraftService } from '@dreryk/react-draft';

const logger = createLogger('CQRS');
setCqrsLogger({
   error: (...args) => logger.error(() => args),
   log: (...args) => logger.info(() => args),
   warn: (...args) => logger.warn(() => args),
});

registerCqrsGateway(
   'egabinet-security-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-security-frontend-api')
);
registerCqrsGateway(
   'egabinet-advertisement-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-advertisement-frontend-api')
);
registerCqrsGateway(
   'egabinet-appointment-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-appointment-frontend-api')
);
registerCqrsGateway(
   'patient-phone-notification-frontend',
   new Gateway('/services/rest/cqrs/1.0/patient-phone-notification-frontend')
);
registerCqrsGateway('statistics-frontend', new Gateway('/services/rest/cqrs/1.0/statistics-frontend'));
registerCqrsGateway('egabinet-ezwm-frontend-api', new Gateway('/services/rest/cqrs/1.0/egabinet-ezwm-frontend-api'));
registerCqrsGateway(
   'egabinet-nfz-configuration-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-nfz-configuration-frontend-api')
);
registerCqrsGateway(
   'egabinet-private-medical-service-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-private-medical-service-frontend-api')
);
registerCqrsGateway(
   'egabinet-rpwdl-integration-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-rpwdl-integration-frontend-api')
);
registerCqrsGateway(
   'egabinet-visit-settlement-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-visit-settlement-frontend-api')
);
registerCqrsGateway(
   'egabinet-docplanner-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-docplanner-frontend-api')
);
registerCqrsGateway(
   'egabinet-patient-portal-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-patient-portal-frontend-api')
);
registerCqrsGateway(
   'egabinet-invoice-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-invoice-frontend-api')
);

registerCqrsGateway(
   'egabinet-survey-frontend-api',
   new Gateway('/services/rest/cqrs/1.0/egabinet-survey-frontend-api')
);

initializeDraftService();

setCqrsClientOptions({
   onError: asyncRejection,
});

export { CqrsClientProvider } from '@dreryk/command-query-react-common';
