import React from 'react';

import { HealthcareProviderType } from '@dreryk/egabinet-healthcare-provider-frontend-api';

import { commonTranslationKeys } from '@dreryk/react-common';
import { LoaderSpinner, Status, StatusType } from '@dreryk/react-components';

import { useCurrentHealthcareProviderTypeQuery } from '~legacy.healthcare-provider';

type AccountConfigurationInfoModalContentLoaderRenderProps = {
   healthcareProviderType: HealthcareProviderType;
};

type AccountConfigurationInfoModalContentLoaderProps = {
   children: (renderProps: AccountConfigurationInfoModalContentLoaderRenderProps) => JSX.Element;
};

export const AccountConfigurationInfoModalContentLoader = (
   props: AccountConfigurationInfoModalContentLoaderProps
): JSX.Element => {
   const {
      data: healthcareProviderType,
      loading: healthcareProviderTypeLoading,
      error: healthcareProviderTypeError,
   } = useCurrentHealthcareProviderTypeQuery();

   if (healthcareProviderTypeLoading) {
      return <LoaderSpinner />;
   }

   if (healthcareProviderTypeError) {
      return (
         <Status
            testId={'fetchError'}
            type={StatusType.NEGATIVE}
            label={commonTranslationKeys.unexpectedErrorHasOccurred}
            displayIndicator
         />
      );
   }

   return props.children({
      healthcareProviderType: healthcareProviderType,
   });
};
