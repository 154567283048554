import { useCallback } from 'react';

import { ConfigurationVerificationResult } from '@dreryk/egabinet-visit-frontend-api';
import { PractitionerConfigurationVerificationResult } from '@dreryk/egabinet-personnel-frontend-api';

import { dispatchSnackbarNegativeEvent } from '@dreryk/react-components';

import { isPractitionerAccountConfigured } from '../practitioner/practitionerAccountConfigurationInfo.hooks';
import { isAccountConfigured, useAccountVerificationModalTitle } from '../accountConfigurationInfo.hooks';

export const useDispatchNotConfiguredAccountSnackbar = (): ((
   accountConfiguration: ConfigurationVerificationResult | null,
   practitionerConfiguration: PractitionerConfigurationVerificationResult | null
) => void) => {
   const text = useAccountVerificationModalTitle();
   return useCallback(
      (accountConfiguration, practitionerConfiguration) => {
         if (
            !isAccountConfigured(accountConfiguration) ||
            !isPractitionerAccountConfigured(practitionerConfiguration)
         ) {
            dispatchSnackbarNegativeEvent(text);
         }
      },
      [text]
   );
};
