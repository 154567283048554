import React from 'react';
import { render } from 'react-dom';
import 'regenerator-runtime';

import './cqrs';
import './deserializers';
import './logging';

import { App } from './App';
import { initializeTranslations } from './i18n';

(async function initialize() {
   try {
      await initializeTranslations();
      render(<App />, document.getElementById('root'));
   } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to load translations.', error);
   }
})();
