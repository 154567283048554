import { createScopedTranslationKeys } from '@dreryk/react-common';

export const translations = {
   pl: {
      app: {
         accountConfigurationInfo: {
            close: 'Zamknij',
            completeConfigurationSoThatDoctorsCanConductVisits:
               'Dokończ konfigurację, aby lekarze mogli przeprowadzać wizyty',
            completeConfigurationToMakeMedicalVisits: 'Dokończ konfigurację, aby przeprowadzać wizyty lekarskie',
            completeConfigurationToMakeVisits: 'Dokończ konfigurację, aby przeprowadzać wizyty',
            configure: 'Konfiguruj',
            doYouNeedHelp: 'Potrzebujesz pomocy? Skorzystaj z <link /> krok po kroku',
            healthcareOrganizationData: 'Dane podmiotu',
            healthcarePracticeData: 'Dane praktyki',
            instruction: 'instrukcji konfiguracji',
            lackOfFullHealthcareProviderConfigurationOnPartAdministrator:
               'Brakuje pełnej konfiguracji po stronie Administratora podmiotu - skontaktuj się z nim, aby móc przeprowadzać wizyty.',
            p1Certificates: 'Certyfikaty P1',
            personalCertificate: 'Certyfikat osobisty',
            peselAndProfession: 'Numer PESEL i zawód',
            pwzAndSpecialization: 'Numer PWZ i specjalizacja',
            ready: 'Gotowe',
         },
         experimental: 'Tryb eksperymentalny',
         mainMenu: {
            calendar: 'Kalendarz',
            helpCenter: 'Centrum pomocy',
            patients: 'Pacjenci',
            statistics: 'Statystyki',
            visits: 'Wizyty',
         },
         payInvoices:
            'Pracujesz w trybie tylko do odczytu. Ureguluj zaległe faktury, by przywrócić pełne działanie programu.',
         settings: {
            tabs: {
               visitTemplates: 'Szablony wizyt',
            },
         },
         subscriptionEnding: {
            anotherSubscriptionYearStart:
               'W dniu <bold>{{date}}</bold> rozpoczyna się kolejny rok subskrypcji oprogramowania drEryk eGabinet.',
            close: 'Zamknij',
            contactUs: 'Skontaktuj się z nami',
            dearUser: 'Drogi Użytkowniku,',
            nextBillingPeriodInvoiceAutomaticPayment:
               'Jeśli dokonałeś zakupu oprogramowania drEryk eGabinet poprzez eSklep przy użyciu karty, płatność za kolejny okres rozliczeniowy zostanie pobrana automatycznie.',
            nextBillingPeriodInvoiceSend: 'Na adres {{email}} prześlemy fakturę za kolejny okres rozliczeniowy.',
            thankYou: 'Dziękujemy!',
            thankYouForBeingWithUs: 'Dziękujemy, że jesteś z nami!',
         },
         userDropdown: {
            myAccount: 'Moje konto',
            myHealthCareInstitution: 'Moja praktyka', // Current value due to MVP target customers, however target value is "Moja Placówka"
            schedule: 'Harmonogram',
            settings: 'Ustawienia',
         },
         whatsNew: 'Co nowego w drEryk eGabinet?',
      },
   },
};

export const appTranslationKeys = createScopedTranslationKeys(translations.pl, 'app');
export const accountConfigurationInfoTranslationKeys = appTranslationKeys.accountConfigurationInfo;
export const userDropdownTranslationKeys = appTranslationKeys.userDropdown;
export const settingsTranslationKeys = appTranslationKeys.settings;
export const subscriptionEndingTranslationKeys = appTranslationKeys.subscriptionEnding;
