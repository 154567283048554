import React from 'react';

import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import { Status, StatusType } from '@dreryk/react-components';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

const HealthcareProviderAdministratorLackConfigurationStatusContent = (): JSX.Element => {
   return (
      <Status
         type={StatusType.NEGATIVE}
         fullWidth
         displayIndicator
         label={accountConfigurationInfoTranslationKeys.lackOfFullHealthcareProviderConfigurationOnPartAdministrator}
         testId={'lackOfFullHealthcareProviderConfigurationOnPartAdministrator'}
      />
   );
};

type HealthcareProviderAdministratorLackConfigurationStatusProps = {
   healthcareProviderDataConfigured: boolean;
   p1CertificateConfigured: boolean;
};

export const HealthcareProviderAdministratorLackConfigurationStatus = (
   props: HealthcareProviderAdministratorLackConfigurationStatusProps
): JSX.Element | null => {
   const protectResource = useResourceProtector();

   if (props.healthcareProviderDataConfigured && props.p1CertificateConfigured) {
      return null;
   }

   return protectResource(
      FeatureId.EGABINETHEALTHCAREPROVIDER_UPDATING_PROVIDER_DATA,
      null,
      <HealthcareProviderAdministratorLackConfigurationStatusContent />
   );
};
