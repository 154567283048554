import { useCallback, useMemo } from 'react';
import { createGlobalState } from 'react-use';

import {
   CurrentPractitionerConfigurationVerificationQuery,
   PractitionerConfigurationVerificationResult as PractitionerConfigurationResult,
} from '@dreryk/egabinet-personnel-frontend-api';

import { CqrsQueryPerformResult, performCqrsQueryFind } from '@dreryk/command-query-react-common';

const usePractitionerVerificationResult = createGlobalState<PractitionerConfigurationResult | null>(null);
export const useFlushPractitionerVerificationState = (): VoidFunction => {
   const [, setPractitionerVerificationResult] = usePractitionerVerificationResult();
   return useCallback(() => {
      setPractitionerVerificationResult(null);
   }, [setPractitionerVerificationResult]);
};

const usePractitionerAccountVerificationModalOpened = createGlobalState<boolean>(false);

export const isPractitionerAccountConfigured = (
   practitionerConfiguration: PractitionerConfigurationResult | null
): boolean => {
   if (!practitionerConfiguration) {
      return false;
   }

   return !Object.keys(practitionerConfiguration).some(
      (key) => practitionerConfiguration[key as keyof PractitionerConfigurationResult] === 'NOT_CONFIGURED'
   );
};

// eslint-disable-next-line max-len
const usePractitionerAccountConfigurationQueryPerformer = (): (() => CqrsQueryPerformResult<PractitionerConfigurationResult | null>) => {
   const [, setPractitionerVerificationResult] = usePractitionerVerificationResult();

   return useCallback(async () => {
      const practitionerConfiguration = await performCqrsQueryFind<PractitionerConfigurationResult>(
         () => new CurrentPractitionerConfigurationVerificationQuery()
      );
      setPractitionerVerificationResult(practitionerConfiguration);
      return practitionerConfiguration;
   }, [setPractitionerVerificationResult]);
};

type UsePractitionerAccountConfigurationInfoModalToolsOptions = {
   onOpen?: VoidFunction;
   onClose?: VoidFunction;
};
type VerifyPractitionerAccountConfiguration = () => Promise<void>;
type UsePractitionerAccountConfigurationInfoModalToolsReturn = {
   practitionerConfigurationModalOpened: boolean;
   practitionerConfigurationModalClose: VoidFunction;
   fetchPractitionerConfiguration: () => CqrsQueryPerformResult<PractitionerConfigurationResult | null>;
   practitionerConfiguration: PractitionerConfigurationResult | null;
   verifyPractitionerAccountConfiguration: VerifyPractitionerAccountConfiguration;
};

const useVerifyPractitionerAccountConfiguration = (onOpen?: VoidFunction): VerifyPractitionerAccountConfiguration => {
   const [, setPractitionerAccountModalOpened] = usePractitionerAccountVerificationModalOpened();
   const fetch = usePractitionerAccountConfigurationQueryPerformer();

   return useCallback(async () => {
      const result = await fetch();
      if (!isPractitionerAccountConfigured(result)) {
         setPractitionerAccountModalOpened(true);
         onOpen?.();
      }
   }, [fetch, onOpen, setPractitionerAccountModalOpened]);
};

export const usePractitionerAccountConfigurationInfoModalTools = (
   options?: UsePractitionerAccountConfigurationInfoModalToolsOptions
): UsePractitionerAccountConfigurationInfoModalToolsReturn => {
   const [practitionerConfiguration] = usePractitionerVerificationResult();
   const [
      practitionerAccountModalOpened,
      setPractitionerAccountModalOpened,
   ] = usePractitionerAccountVerificationModalOpened();
   const fetch = usePractitionerAccountConfigurationQueryPerformer();
   const verifyPractitionerAccountConfiguration = useVerifyPractitionerAccountConfiguration();

   const handleClose = useCallback(() => {
      setPractitionerAccountModalOpened(false);
      options?.onClose?.();
   }, [options, setPractitionerAccountModalOpened]);

   return useMemo(
      () => ({
         practitionerConfigurationModalOpened: practitionerAccountModalOpened,
         practitionerConfigurationModalClose: handleClose,
         fetchPractitionerConfiguration: fetch,
         verifyPractitionerAccountConfiguration: verifyPractitionerAccountConfiguration,
         practitionerConfiguration: practitionerConfiguration,
      }),
      [
         fetch,
         handleClose,
         practitionerConfiguration,
         practitionerAccountModalOpened,
         verifyPractitionerAccountConfiguration,
      ]
   );
};
