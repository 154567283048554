import { Duration, LocalDate, LocalTime, Period } from '@js-joda/core';

import {
   Building,
   CompleteAddress,
   Country,
   Flat,
   PartialAddress,
   Postcode,
   Street,
   Town,
} from '@dreryk/address-common-api';
import { Color } from '@dreryk/color-common-api';
import {
   Icd10Code,
   Icd10Entry,
   Icd10Name,
   Icd9Code,
   Icd9Entry,
   Icd9Name,
} from '@dreryk/egabinet-icd-dictionary-published-language-api';
import {
   AuthorizedPersonExternalIdentifier,
   GuardianExternalIdentifier,
   IdentityDocument,
   PatientExternalIdentifier,
} from '@dreryk/egabinet-patient-additional-api';
import { PractitionerId } from '@dreryk/egabinet-personnel-published-language-api';
import { EmailAddress } from '@dreryk/email-address-common-api';
import { HealthcareProviderRegisterBookNumber } from '@dreryk/healthcare-provider-register-book-number-api';
import { MedicalLicenceNumber } from '@dreryk/medical-licence-number-common-api';
import { Money } from '@dreryk/money-common-api';
import { FirstName, LastName, MiddleName } from '@dreryk/person-name-common-api';
import {
   GuardianPesel,
   NationalIdentificationNumber,
   NationalIdentificationNumberCountry,
   Passport,
   Pesel,
} from '@dreryk/personal-external-identifier-common-api';
import { PhoneNumber } from '@dreryk/phone-number-common-api';
import { Regon, UnitRegon } from '@dreryk/regon-common-api';
import { TaxIdentifier } from '@dreryk/tax-identifier-common-api';
import { TimeSlot } from '@dreryk/time-slot-common-api';

import { registerDeserializer, registerSerializer } from '@dreryk/command-query-ws-client';
import {
   DurationDTO,
   DurationSerialization,
   LocalDateSerialization,
   LocalTimeSerialization,
   PeriodDTO,
   PeriodSerialization,
} from '@dreryk/date-json-serialization-common';
import { Range } from '@dreryk/watermelon';

registerSerializer(Period, (period: Period) => PeriodSerialization.toJSON(period));
registerDeserializer(PeriodDTO.CLASS, (json) => PeriodSerialization.fromJSON(json as PeriodDTO));
registerSerializer(Duration, (duration: Duration) => DurationSerialization.toJSON(duration));
registerDeserializer(DurationDTO.CLASS, (json) => DurationSerialization.fromJSON(json as DurationDTO));
registerSerializer(LocalDate, (localDate: LocalDate) => LocalDateSerialization.toJSON(localDate));
registerDeserializer(LocalDateSerialization.CLASS, (json) => LocalDateSerialization.fromJSON(json));
registerSerializer(LocalTime, (localTime: LocalTime) => LocalTimeSerialization.toJSON(localTime));
registerDeserializer(LocalTimeSerialization.CLASS, (json) => LocalTimeSerialization.fromJSON(json));
registerDeserializer(EmailAddress.CLASS, (obj) => EmailAddress.fromJSON(obj));
registerDeserializer(GuardianExternalIdentifier.CLASS, (obj) => GuardianExternalIdentifier.fromJSON(obj));
registerDeserializer(AuthorizedPersonExternalIdentifier.CLASS, (obj) => {
   return AuthorizedPersonExternalIdentifier.fromJSON(obj);
});
registerDeserializer(GuardianPesel.CLASS, (obj) => GuardianPesel.fromJSON(obj));
registerDeserializer(HealthcareProviderRegisterBookNumber.CLASS, (obj) => {
   return HealthcareProviderRegisterBookNumber.fromJSON(obj);
});
registerDeserializer(MedicalLicenceNumber.CLASS, (obj) => MedicalLicenceNumber.fromJSON(obj));
registerDeserializer(PatientExternalIdentifier.CLASS, (obj) => PatientExternalIdentifier.fromJSON(obj));
registerDeserializer(Pesel.CLASS, (obj) => Pesel.fromJSON(obj));
registerDeserializer(PhoneNumber.CLASS, (obj) => PhoneNumber.fromJSON(obj));
registerDeserializer(Postcode.CLASS, (obj) => Postcode.fromJSON(obj));
registerDeserializer(Regon.CLASS, (obj) => Regon.fromJSON(obj));
registerDeserializer(UnitRegon.CLASS, (obj) => UnitRegon.fromJSON(obj));
registerDeserializer(TaxIdentifier.CLASS, (obj) => TaxIdentifier.fromJSON(obj));
registerDeserializer(Country.CLASS, (obj) => Country.fromJSON(obj));
registerDeserializer(Town.CLASS, (obj) => Town.fromJSON(obj));
registerDeserializer(Street.CLASS, (obj) => Street.fromJSON(obj));
registerDeserializer(Building.CLASS, (obj) => Building.fromJSON(obj));
registerDeserializer(Flat.CLASS, (obj) => Flat.fromJSON(obj));
registerDeserializer(CompleteAddress.CLASS, (obj) => CompleteAddress.fromJSON(obj));
registerDeserializer(PartialAddress.CLASS, (obj) => PartialAddress.fromJSON(obj));
registerDeserializer(FirstName.CLASS, (obj) => FirstName.fromJSON(obj));
registerDeserializer(MiddleName.CLASS, (obj) => MiddleName.fromJSON(obj));
registerDeserializer(LastName.CLASS, (obj) => LastName.fromJSON(obj));
registerDeserializer(Passport.CLASS, (obj) => Passport.fromJSON(obj));
registerDeserializer(NationalIdentificationNumber.CLASS, (obj) => NationalIdentificationNumber.fromJSON(obj));
registerDeserializer(NationalIdentificationNumberCountry.CLASS, (obj) => {
   return NationalIdentificationNumberCountry.fromJSON(obj);
});
registerDeserializer(Range.CLASS, (obj) => Range.fromJSON(obj));
registerDeserializer(IdentityDocument.CLASS, (obj) => IdentityDocument.fromJSON(obj));
registerDeserializer(Money.CLASS, (obj) => Money.fromJSON(obj));
registerDeserializer(Color.CLASS, (obj) => Color.fromJSON(obj));
registerDeserializer(Icd10Entry.CLASS, (obj) => Icd10Entry.fromJSON(obj));
registerDeserializer(Icd10Code.CLASS, (obj) => Icd10Code.fromJSON(obj));
registerDeserializer(Icd10Name.CLASS, (obj) => Icd10Name.fromJSON(obj));
registerDeserializer(Icd9Entry.CLASS, (obj) => Icd9Entry.fromJSON(obj));
registerDeserializer(Icd9Code.CLASS, (obj) => Icd9Code.fromJSON(obj));
registerDeserializer(Icd9Name.CLASS, (obj) => Icd9Name.fromJSON(obj));
registerDeserializer(TimeSlot.CLASS, (obj) => TimeSlot.fromJSON(obj));
registerDeserializer(PractitionerId.CLASS, (obj) => PractitionerId.fromJSON(obj));
