import { useCallback, useMemo } from 'react';
import { createGlobalState } from 'react-use';

import {
   ConfigurationVerificationResult,
   CurrentDoctorConfigurationVerificationQuery,
} from '@dreryk/egabinet-visit-frontend-api';

import { CqrsQueryPerformResult, performCqrsQueryFind } from '@dreryk/command-query-react-common';
import { FeatureId, useResourceProtector } from '@dreryk/egabinet-security-frontend';
import { TranslatableText } from '@dreryk/react-common';

import { accountConfigurationInfoTranslationKeys } from '../../translations';

const useLastVerificationResult = createGlobalState<ConfigurationVerificationResult | null>(null);
export const useFlushAccountVerificationState = (): VoidFunction => {
   const [, setLastVerificationResult] = useLastVerificationResult();
   return useCallback(() => {
      setLastVerificationResult(null);
   }, [setLastVerificationResult]);
};

export const isAccountConfigured = (configurationData: ConfigurationVerificationResult | null): boolean =>
   Boolean(
      configurationData &&
         Object.keys(configurationData).every(
            (configurationKey) => configurationData[configurationKey as keyof ConfigurationVerificationResult]
         )
   );

// eslint-disable-next-line max-len
export const useAccountConfigurationDeferredQuery = (): (() => CqrsQueryPerformResult<ConfigurationVerificationResult | null>) => {
   const [lastConfigurationResult, setLastConfigurationResult] = useLastVerificationResult();
   if (lastConfigurationResult && isAccountConfigured(lastConfigurationResult)) {
      return async () => Promise.resolve(lastConfigurationResult);
   }
   return async () => {
      const result = await performCqrsQueryFind<ConfigurationVerificationResult>(
         () => new CurrentDoctorConfigurationVerificationQuery()
      );
      setLastConfigurationResult(result);
      return result;
   };
};

type VerifyAccountConfiguration = () => Promise<void>;
type UseAccountConfigurationInfoModalToolsReturn = {
   opened: boolean;
   close: VoidFunction;
   fetchConfigurationData: () => CqrsQueryPerformResult<ConfigurationVerificationResult | null>;
   configurationData: ConfigurationVerificationResult | null;
   verifyAccountConfiguration: VerifyAccountConfiguration;
   title: TranslatableText;
};

type UseAccountConfigurationInfoModalToolsOptions = {
   onOpen?: VoidFunction;
   onClose?: VoidFunction;
};

export const useAccountVerificationModalOpened = createGlobalState<boolean>(false);

export const useVerifyAccountConfiguration = (onOpen?: VoidFunction): VerifyAccountConfiguration => {
   const [, setAccountConfigurationModalOpened] = useAccountVerificationModalOpened();
   const fetch = useAccountConfigurationDeferredQuery();
   return useCallback(async () => {
      const result = await fetch();
      if (!isAccountConfigured(result)) {
         setAccountConfigurationModalOpened(true);
         onOpen?.();
      }
   }, [fetch, onOpen, setAccountConfigurationModalOpened]);
};

export const useAccountVerificationModalTitle = (): TranslatableText => {
   const protectResource = useResourceProtector();

   return protectResource(
      FeatureId.EGABINETPERSONNEL_UPDATING_DOCTOR_PERSONAL_DATA,
      accountConfigurationInfoTranslationKeys.completeConfigurationToMakeMedicalVisits,
      accountConfigurationInfoTranslationKeys.completeConfigurationToMakeVisits
   );
};

export const useAccountConfigurationInfoModalTools = (
   options?: UseAccountConfigurationInfoModalToolsOptions
): UseAccountConfigurationInfoModalToolsReturn => {
   const [accountConfigurationData] = useLastVerificationResult();
   const [accountConfigurationModalOpened, setAccountConfigurationModalOpened] = useAccountVerificationModalOpened();
   const fetch = useAccountConfigurationDeferredQuery();
   const verifyAccountConfiguration = useVerifyAccountConfiguration();
   const title = useAccountVerificationModalTitle();

   const handleClose = useCallback(() => {
      setAccountConfigurationModalOpened(false);
      options?.onClose?.();
   }, [options, setAccountConfigurationModalOpened]);
   return useMemo(
      () => ({
         opened: accountConfigurationModalOpened,
         close: handleClose,
         fetchConfigurationData: fetch,
         verifyAccountConfiguration: verifyAccountConfiguration,
         configurationData: accountConfigurationData,
         title: title,
      }),
      [accountConfigurationModalOpened, fetch, accountConfigurationData, handleClose, verifyAccountConfiguration, title]
   );
};
